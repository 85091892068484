@import "../../styles.scss";
.support_data {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 139px;
}
.support_wrapper {
  width: 100%;
}
.support_icon {
  height: 77px;
  min-height: 77px;
  width: 66px;
  min-width: 66px;
  position: relative;
  margin-bottom: 26px;
}
.support_icon__top {
  position: absolute;
  top: 0;
  right: 0;
}
.support_icon__bottom {
  position: absolute;
  bottom: 0;
  left: 0;
}
.support_title {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  letter-spacing: 0.02em;
  color: #2D2D2D;
  margin-bottom: 9px;
  text-align: center;
}
.support_subtitle {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #2D2D2D;
  margin-bottom: 27px;
}
@media (max-width: 976px) {
  .support_data {
    margin-bottom: 96px;
  }
}
