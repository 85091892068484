$backgroundColor: #F5FAFF;
$primaryColor: #1890FF;
$textColorPrimary: #2D2D2D;
$textColorSecondary: #333333;
$textColorLight: #FFFFFF;
$fontFamily: 'Averta CY';
$mainFontSize: 18px;
.app {
  background-color: $backgroundColor;
  font-family: $fontFamily;
  font-weight: 400;
  font-size: 18px;
  width: 100%;
  display: flex;
  justify-content: center;
}

#__next {
  height: 100%;
  width: 100%;
}

.spacer_h8 {
  height: 8px;
  width: 1px;
}

.spacer_w8 {
  height: 1px;
  width: 8px;
}

.spacer_h16 {
  height: 16px;
  width: 1px;
}

/*
100
Thin (Hairline)
200
Extra Light (Ultra Light)
300
Light
400
Normal
500
Medium
600
Semi Bold (Demi Bold)
700
Bold
800
Extra Bold (Ultra Bold)
900
Black (Heavy)
 */
@font-face {
  font-family: 'Averta CY';
  src: url("public/AvertaCY/Averta Cyrillic Black.otf");
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: 'Averta CY';
  src: url("public/AvertaCY/Averta Cyrillic Black Italic.otf");
  font-style: italic;
  font-weight: 900;
}

@font-face {
  font-family: 'Averta CY';
  src: url("public/AvertaCY/Averta Cyrillic ExtraBold.otf");
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: 'Averta CY';
  src: url("public/AvertaCY/Averta Cyrillic ExtraBold Italic.otf");
  font-style: italic;
  font-weight: 800;
}

@font-face {
  font-family: 'Averta CY';
  src: url("public/AvertaCY/Averta Cyrillic Bold.otf");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'Averta CY';
  src: url("public/AvertaCY/Averta Cyrillic Bold.otf");
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: 'Averta CY';
  src: url("public/AvertaCY/Averta Cyrillic SemiBold.otf");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'Averta CY';
  src: url("public/AvertaCY/Averta Cyrillic SemiBold.otf");
  font-style: italic;
  font-weight: 600;
}

@font-face {
  font-family: 'Averta CY';
  src: url("public/AvertaCY/Averta Cyrillic Regular.otf");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Averta CY';
  src: url("public/AvertaCY/Averta Cyrillic Regular Italic.otf");
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'Averta CY';
  src: url("public/AvertaCY/Averta Cyrillic Light.otf");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'Averta CY';
  src: url("public/AvertaCY/Averta Cyrillic Light Italic.otf");
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: 'Averta CY';
  src: url("public/AvertaCY/Averta Cyrillic Thin.otf");
  font-style: normal;
  font-weight: 200;
}

@font-face {
  font-family: 'Averta CY';
  src: url("public/AvertaCY/Averta Cyrillic Thin Italic.otf");
  font-style: italic;
  font-weight: 200;
}

@font-face {
  font-family: 'Averta CY';
  src: url("public/AvertaCY/Averta Cyrillic Extra Thin.otf");
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: 'Averta CY';
  src: url("public/AvertaCY/Averta Cyrillic Extra Thin Italic.otf");
  font-style: italic;
  font-weight: 100;
}